import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2018 = () => (
  <Layout
    title="3M 2018 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2018 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2018 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <Link
                to="../3M-2020-YSC-Finalist-Presentations"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: 'd7fa877a-dcb3-49fc-8fc5-23d5784dc1a7',
                    poster: '2018-finalist-thumbnail-rishab-jain.jpg',
                    title: '3M 2018 YSC Winner Presentation: Rishab Jain',
                    description:
                      'View 2018 Challenge Winner Rishab Jain’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '797cb604-659e-47e0-9aff-969efa93b452',
                    poster: '2018-finalist-thumbnail-julia-gelfond.jpg',
                    title: '3M 2018 YSC Finalist Presentation: Julia Gelfond',
                    description:
                      'View 2018 Challenge Finalist Julia Gelfond’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '6a1a9b76-3a5f-4a4a-a1c0-df5a8267740a',
                    poster: '2018-finalist-thumbnail-cameron-sharma.jpg',
                    title: '3M 2018 YSC Finalist Presentation: Cameron Sharma',
                    description:
                      'View 2018 Challenge Finalist Cameron Sharma’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '0290bd03-f65f-4708-9228-f9e6b8d6b1e4',
                    poster: '2018-finalist-thumbnail-anna-du.jpg',
                    title: '3M 2018 YSC Finalist Presentation: Anna Du',
                    description:
                      'View 2018 Challenge Finalist Anna Du’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '39128e9c-d689-4450-98f1-da2fa19458e3',
                    poster: '2018-finalist-thumbnail-mehaa-amirthalingham.jpg',
                    title:
                      '3M 2018 YSC Finalist Presentation: Mehaa Amirthalingam',
                    description:
                      'View 2018 Challenge Finalist Mehaa Amirthalingam’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'eb102172-f054-4001-899c-c0196ee55eb2',
                    poster: '2018-finalist-thumbnail-krish-wadhwani.jpg',
                    title: '3M 2018 YSC Finalist Presentation: Krish Wadhwani',
                    description:
                      'View 2018 Challenge Finalist Krish Wadhwani’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'ff72c1c7-7de0-4a31-bf07-fad9e7277bf3',
                    poster: '2018-finalist-thumbnail-leo-wylonis.jpg',
                    title: '3M 2018 YSC Finalist Presentation: Leo Wylonis',
                    description:
                      'View 2018 Challenge Finalist Leo Wylonis’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'ebcdfe68-ba34-4c10-abec-c02bafc9539e',
                    poster: '2018-finalist-thumbnail-sriram-bjimaraju.jpg',
                    title:
                      '3M 2018 YSC Finalist Presentation: Sriram Bjimaraju',
                    description:
                      'View 2018 Challenge Finalist Sriram Bjimaraju’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'acd088c6-02d2-47c5-84bb-cbf64afbd73d',
                    poster: '2018-finalist-thumbnail-theodore-jiang.jpg',
                    title: '3M 2018 YSC Finalist Presentation: Theodore Jiang',
                    description:
                      'View 2018 Challenge Finalist Theodore Jiang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'c4d48370-a67f-4e67-9ee0-1cb9ac3d12c5',
                    poster: '2018-finalist-thumbnail-zachary-hessler.jpg',
                    title: '3M 2018 YSC Finalist Presentation: Zachary Hessler',
                    description:
                      'View 2018 Challenge Finalist Zachary Hessler’s Presentation.',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2017-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2018 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2018;
